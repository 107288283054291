import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import ArticleListItem from "../components/ListItems/ArticleListItem";
import Title from "../components/Title";
import {cssCleanClass} from "../Utils/functions";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const TagsArticleList = ({ pageContext, data, location }) => {
  const {
    breadcrumb: {crumbs},
    currentPage,
    numPages,
    basePath
  } = pageContext

  return (
    <Layout>
      <Metas title={pageContext.name} />
      <section className="section page-breadcrumb is-small has-background-light">
        <div className="container">
            <CustomBreadcrumb crumbs={crumbs}/>
        </div>
      </section>

      <section className="section page-content">
        <div className="container">
          <Title title="Actualités" subtitle="Tout savoir sur la commune" className="has-text-centered"/>

          {pageContext.tags && (
            <div className="articles-themes has-text-centered">
              <h2 className="title is-6 is-uppercase">Filtrer par catégorie</h2>
              <div className="tags is-centered">
                {pageContext.tags.map((tag, index) =>
                    parseInt(pageContext.tid) === parseInt(tag.id) ? (
                        <span key={index} className="tag is-rounded no-scale is-active">
                            <Link to={`/actualites`}>{tag.name}</Link>
                        </span>
                    ) : (
                        <span key={index} className={`tag is-rounded theme theme-${cssCleanClass(tag.name)}`}>
                            <Link to={`/actualites${tag.path}`}>{tag.name}</Link>
                        </span>
                    )
                )}
              </div>
            </div>
          )}

          <div className="columns is-multiline">
            {data.articles.nodes.map((node, index) => (
              <div key={index} className="column is-4">
                <ArticleListItem content={node} hideTags />
              </div>
            ))}

            <Pagination
              path={location.pathname}
              currentPage={currentPage}
              numPages={numPages}
              basePath={basePath}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TagsArticleList

export const query = graphql`
    query actualiteTagsListQuery($tid: Int!, $skip: Int!, $limit: Int!) {
        articles: allNodeArticle(
            filter: {
                status: {eq: true},
                relationships: {
                    field_tags: {
                        drupal_internal__tid: {eq: $tid}
                    }
                }
            },
            sort: { fields: created, order: DESC },
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                id
                title
                created
                path {
                    alias
                }
                body {
                    value
                    summary
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 480
                                    height: 270
                                    quality: 100
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        path {
                            alias
                        }
                    }
                }
            }
        }
    }`
